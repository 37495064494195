<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      檔期管理

      <v-btn color="primary" @click="openDialog('create')">
        <v-icon>mdi-plus</v-icon>
        建立檔期
      </v-btn>
    </h3>
    <v-row align="center">
      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="filterQuery.date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedDate"
              v-bind="attrs"
              v-on="on"
              label="日期區間"
              outlined
              readonly
              dense
              hide-details
              clearable
              class="dates-input"
              @click:clear="filterQuery.date = []"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterQuery.date"
            range
            no-title
            scrollable
            locale="zh-tw"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> 取消 </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(filterQuery.date)"
            >
              確定
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="關鍵字"
          v-model.trim="filterQuery.keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-select
          label="店點"
          v-model="filterQuery.branch"
          outlined
          dense
          hide-details
          clearable
          :items="branchOptions"
        >
        </v-select>
      </v-col>
      <v-col cols="2">
        <v-select
          label="機台"
          v-model="filterQuery.machine"
          outlined
          dense
          hide-details
          clearable
          :items="getMachineOptions(filterQuery.branch)"
        >
        </v-select>
      </v-col>
      <!-- <v-col cols="1">
        <v-btn color="primary">查詢</v-btn>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          item-key="index"
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [10, 30, 50, -1],
          }"
        >
          <template v-slot:item.product="{ item }">
            {{ item.product.name || "---" }}
          </template>
          <template v-slot:item.start="{ item }">
            {{ item.start.date || "---" }}
          </template>
          <template v-slot:item.end="{ item }">
            {{ item.end.date || "---" }}
          </template>
          <template v-slot:item.startCoin="{ item }">
            {{ item.start.coin || "---" }}
          </template>
          <template v-slot:item.startEye="{ item }">
            {{ item.start.eye || "---" }}
          </template>
          <template v-slot:item.endCoin="{ item }">
            {{ item.end.coin || "---" }}
          </template>
          <template v-slot:item.endEye="{ item }">
            {{ item.end.eye || "---" }}
          </template>
          <template v-slot:item.action="{ item }">
            <div class="my-1">
              <v-btn
                class="my-1 mx-2"
                color="success"
                @click="openDialog('edit', item)"
              >
                編輯
              </v-btn>
              <v-btn
                class="my-1 mx-2"
                color="error"
                @click="removePeriod(item)"
              >
                刪除
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-if="dialog.open" v-model="dialog.open" persistent>
      <v-card>
        <v-card-title>
          {{ dialog.type === "create" ? "建立檔期" : "編輯檔期" }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                label="店點"
                v-model="payload.branch"
                v-on:change="handleRelatedSelect"
                :items="[
                  { text: '請選擇', value: '', disabled: true },
                  { text: '文賢店', value: '文賢' },
                  { text: '崇善店', value: '崇善' },
                  { text: '奇美店', value: '奇美' },
                ]"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                label="機台"
                v-model="payload.machine"
                :items="machineOptions"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                label="商品"
                v-model="payload.product"
                :items="productsOptions"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <h4>上架日期與紀錄</h4>
              <DateTimePicker
                :value="payload.start.date"
                @update="(val) => (payload.start.date = val)"
              >
              </DateTimePicker>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    class="mt-3"
                    v-model.number="payload.start.coin"
                    label="出幣量"
                    outlined
                    dense
                    hide-details
                    clearable
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    class="mt-3"
                    v-model.number="payload.start.eye"
                    label="電眼"
                    outlined
                    dense
                    hide-details
                    clearable
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <h4>下架日期與紀錄</h4>
              <DateTimePicker
                :value="payload.end.date"
                @update="(val) => (payload.end.date = val)"
                :disable-date-config="{
                  markDate: payload.start.date,
                  relation: '<=',
                }"
              >
              </DateTimePicker>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    class="mt-3"
                    v-model.number="payload.end.coin"
                    label="出幣量"
                    outlined
                    dense
                    hide-details
                    clearable
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    class="mt-3"
                    v-model.number="payload.end.eye"
                    label="電眼"
                    outlined
                    dense
                    hide-details
                    clearable
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="clear" class="bt-text-large">
            取消
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="dialog.type === 'create'"
            text
            color="primary"
            @click="createPeriod"
            class="bt-text-large"
          >
            建立
          </v-btn>
          <v-btn
            v-else
            text
            color="primary"
            @click="updatePeriod(payload)"
            class="bt-text-large"
          >
            儲存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DateTimePicker from "@/components/dashboard/period/DateTimePicker.vue";
import machineNum from "@/config/machineNum.json";
import util from "@/mixins/util";
import dayjs from "dayjs";
import _ from "lodash";

import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default {
  name: "Purchase",
  mixins: [util],
  components: { DateTimePicker },
  data: () => ({
    periods: [],
    products: [],
    payload: {
      branch: null,
      machine: null,
      product: null,
      start: { coin: 0, eye: 0, date: "" },
      end: { coin: 0, eye: 0, date: "" },
    },
    dialog: { type: "create", open: false },
    filterQuery: {
      keyword: "",
      branch: "",
      machine: "",
      date: [],
    },
    branchOptions: [
      { text: "請選擇", value: "", disabled: true },
      { text: "文賢店", value: "文賢" },
      { text: "崇善店", value: "崇善" },
      { text: "奇美店", value: "奇美" },
    ],
    headers: [
      {
        text: "店點",
        value: "branch",
        align: "left",
        sortable: true,
        cellClass: "font-weight-regular",
      },
      {
        text: "機台位",
        value: "machine",
        align: "left",
        sortable: true,
        cellClass: "font-weight-regular",
      },
      {
        text: "機台商品",
        value: "product",
        align: "left",
        sortable: false,
      },
      {
        text: "上架時間",
        value: "start",
        align: "left",
        sortable: true,
        sort: (a, b) => {
          if (dayjs(a.date).isAfter(dayjs(b.date))) {
            return -1;
          }
          if (dayjs(a.date).isBefore(dayjs(b.date))) {
            return 1;
          }
          return 0;
        },
      },
      {
        text: "下架時間",
        value: "end",
        align: "left",
        sortable: true,
        sort: (a, b) => {
          if (dayjs(a.date).isAfter(dayjs(b.date))) {
            return -1;
          }
          if (dayjs(a.date).isBefore(dayjs(b.date))) {
            return 1;
          }
          return 0;
        },
      },
      {
        text: "上架金額",
        value: "startCoin",
        align: "left",
        sortable: false,
      },
      {
        text: "上架電眼",
        value: "startEye",
        align: "left",
        sortable: false,
      },
      {
        text: "下架金額",
        value: "endCoin",
        align: "left",
        sortable: false,
      },
      {
        text: "下架電眼",
        value: "endEye",
        align: "left",
        sortable: false,
      },
      {
        text: "操作",
        value: "action",
        align: "center",
        sortable: false,
      },
    ],
    datetimePanelOpen: { start: false, end: false },
    showTimePanel: { start: false, end: false },
    date: "",
    menu: false,
  }),
  computed: {
    _: () => _,
    productsOptions() {
      return _.map(this.products, (o) => ({
        text: o.name,
        value: o,
      }));
    },
    machineOptions() {
      if (this.payload.branch) {
        return machineNum[this.payload.branch].map((machine) => {
          return { text: machine, value: machine };
        });
      }
      return [{ text: "請先選擇店點", value: "", disabled: true }];
    },
    formattedDate() {
      const date = this.filterQuery.date;
      return date[0] && date[1]
        ? dayjs(date[0]).format("YYYY-MM-DD") +
            " ~ " +
            dayjs(date[1]).format("YYYY-MM-DD")
        : "";
    },
    filterData() {
      let data = this.periods;
      const { keyword, branch, date, machine } = this.filterQuery;

      if (date.length === 2) {
        const date = this.filterQuery.date;
        data = data.filter(
          (period) =>
            dayjs(period.start.date).isSameOrAfter(date[0]) &&
            dayjs(period.end.date).isSameOrBefore(date[1])
        );
      }
      if (keyword) {
        data = data.filter(
          (period) =>
            period.machine == keyword || period.product.name?.includes(keyword)
        );
      }
      if (branch) {
        data = data.filter((period) => period.branch === branch);
      }
      if (machine) {
        data = data.filter((period) => period.machine === machine);
      }

      return data;
    },
  },
  async created() {
    this.resetPayload();
    await this.getPeriods();
    await this.getProducts();
    await this.load();
  },
  methods: {
    async getPeriods() {
      const { data } = await this.axios.get(`/period`);
      this.periods = data
        .filter((period) => dayjs(period.start.date).isAfter("2024-02-16"))
        .sort((a, b) => {
          if (dayjs(a.start.date).isAfter(dayjs(b.start.date))) {
            return -1;
          }
          if (dayjs(a.start.date).isBefore(dayjs(b.start.date))) {
            return 1;
          }
          return 0;
        });
    },
    async getProducts() {
      const res = await this.axios.get(`/product?type=機台商品`);
      let product = res.data.map((item) => ({
        text: item.name,
        value: item._id,
      }));
      product = _.uniqBy(product, "text");
      product.unshift({ text: "-", value: "" });
      this.products = product;
    },

    async update(period, key, e) {
      if (e.value) {
        await this.axios.put(`/period/${period._id}`, {
          [key]: e.value,
        });
        period[key] = e.value;
        return;
      }
      const value = e;
      await this.axios.put(`/period/${period._id}`, {
        [key]: value,
      });
      period[key] = value;
      this.$forceUpdate();
    },
    async updatePeriod(period) {
      const { _id, ...payload } = period;
      await this.axios.put(`/period/${_id}`, payload);
      this.$toast.success("建立成功");
      this.load();
      this.clear();
    },
    async createPeriod() {
      let payload = _.cloneDeep(this.payload);
      // payload.items = _.filter(payload.items, (o) => o.product && o.required);
      if (!payload.branch || !payload.machine) {
        return this.$toast.error("請選擇店點與機台");
      }

      if (!payload.product) {
        return this.$toast.error("請選擇商品");
      }

      let { data } = await this.axios.post(`/period`, payload);
      this.$toast.success("建立成功");
      this.load();
      this.clear();
    },
    async getProducts() {
      let { data } = await this.axios.get(`/product?type=機台商品`);
      data = data.filter((o) => o.name);
      this.products = data;
    },

    async load() {
      await this.getPeriods();
      // let { data } = await this.axios.get(`/period`);
      // this.periods = data;
    },
    async removePeriod(period) {
      if (!confirm(`確定刪除此檔期?`)) return;
      await this.axios.delete(`/period/${period._id}`);
      this.load();
    },
    clear() {
      // this.dialog.create = false;
      this.dialog.open = false;
    },
    resetPayload() {
      this.payload = {
        branch: null,
        machine: null,
        product: null,
        start: { coin: 0, eye: 0, date: "" },
        end: { coin: 0, eye: 0, date: "" },
      };
    },
    openDialog(type, payload) {
      if (payload) this.payload = _.cloneDeep(payload);
      else this.resetPayload();
      // this.dialog[type] = true;
      this.dialog.type = type;
      this.dialog.open = true;
    },
    toggleTimePanel(type) {
      this.showTimePanel[type] = !this.showTimePanel[type];
    },
    handleOpenChange(type) {
      this.showTimePanel[type] = false;
    },
    getMachineOptions(branch) {
      if (!branch) return [{ text: "請先選擇店點", value: "", disabled: true }];
      return machineNum[branch].map((machine) => {
        return { text: machine, value: machine };
      });
    },
    handleRelatedSelect() {
      if (this.machineOptions.includes(this.payload.machine)) {
        return;
      }
      this.payload.machine = "";
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.dates-input {
  min-width: 150px;
}
</style>
