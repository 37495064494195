<template>
  <date-picker
    @close="handleOpenChange"
    v-model="date"
    type="datetime"
    :show-time-panel="showTimePanel"
    :show-second:="false"
    :open.sync="datetimePanelOpen"
    value-type="format"
    :disabled-date="getDisableDate"
    format="YYYY/MM/DD HH:mm"
    class="modify-datepicker"
  >
    <template v-slot:footer>
      <button class="mx-btn mx-btn-text" @click="toggleTimePanel">
        {{ showTimePanel ? "選擇日期" : "選擇時間" }}
      </button>
    </template>
  </date-picker>
</template>

<script>
// import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
// import flatPickr from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
import dayjs from "dayjs";

import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default {
  name: "DateTimePicker",
  props: ["value", "disableDateConfig"],
  components: { DatePicker },
  data: () => ({ menu: false, showTimePanel: false, datetimePanelOpen: false }),
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update", val);
      },
    },
    getDisableDate() {
      const markDate = this.disableDateConfig?.markDate;
      const relation = this.disableDateConfig?.relation;

      if (markDate && relation === "<=") {
        return function (date) {
          return dayjs(date).isSameOrBefore(markDate);
        };
      }
      if (markDate && relation === ">=") {
        return function (date) {
          return dayjs(date).isSameOrAfter(markDate);
        };
      }

      return function (date) {
        return false;
      };
    },
  },
  methods: {
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modify-datepicker {
  min-height: 40px;
  padding: 8px 0;
  border-radius: 4px;
  width: 100%;

  >>> .mx-input {
    height: 42px;
  }
}
</style>

<style lang="scss">
.modify-datepicker {
  .mx-input {
    height: 42px;
    font-size: 16px;
    border-color: #a8a8a8;
  }
}
</style>
